import React, { useEffect, useState } from 'react'
import { useUserSession } from 'hooks'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'

import Banner from 'components/Banner'

import GET_REP_TRADE_REFERENCES_BANNER_DATA from './graphql/GetRepTradeReferencesBannerData.graphql'
import { GetRepTradeReferencesBannerData } from './graphql/__generated__/GetRepTradeReferencesBannerData'
import { RepKindEnum } from '../../../../../__generated__/globalTypes'

const AddTradeReferencesBanner = () => {
  const router = useRouter()
  const { isRep } = useUserSession()
  const [showBanner, setShowBanner] = useState(false)

  const [getRepTradeReferencesBannerData] = useLazyQuery<GetRepTradeReferencesBannerData>(
    GET_REP_TRADE_REFERENCES_BANNER_DATA
  )

  useEffect(() => {
    const loadData = async () => {
      if (!router.isReady) return

      if (isRep) {
        const { data } = await getRepTradeReferencesBannerData()
        const isIndependent = data?.currentRep?.kind === RepKindEnum.INDEPENDENT
        const hasNoTradeReferences = (data?.currentRep?.tradeReferences?.nodesCount ?? 0) === 0
        setShowBanner(isIndependent && hasNoTradeReferences)
      }
    }

    loadData()
  }, [router.isReady, getRepTradeReferencesBannerData, isRep])

  if (!showBanner) return null

  return (
    <Banner
      kind="setup"
      icon="user"
      borderlessIcon
      link={{ href: '/rh/profile#trade-references', label: 'Add Trade References' }}>
      Complete your Sales Rep profile by adding Trade References
    </Banner>
  )
}

export default AddTradeReferencesBanner

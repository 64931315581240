import React from 'react'
import Link from 'next/link'
import classnames from 'classnames'

import Icon from 'components/Icon'
import styles from './Banner.module.css'

export type BannerLink = {
  label: string
  href: string
}

export type BannerProps = {
  kind?: 'info' | 'error' | 'warning' | 'setup' | 'positive'
  icon?: string
  children?: React.ReactNode
  link?: BannerLink
  links?: BannerLink[]
  rightContent?: React.ReactNode
  className?: string
  vAlign?: 'top' | 'center' | 'bottom'
  borderlessIcon?: boolean
}

const Banner = ({
  kind = 'info',
  icon = 'alert-triangle',
  children,
  links,
  link,
  className,
  rightContent,
  vAlign = 'center',
  borderlessIcon = false
}: BannerProps) => (
  <div className={classnames(styles.container, styles[kind], className)}>
    <div className={classnames(styles.containerInner, styles[vAlign])}>
      <div className={classnames(styles.contentContainer, styles[vAlign])}>
        <div className={classnames(styles.iconContainer, { [styles.borderlessIcon]: borderlessIcon })}>
          <Icon kind={icon} size={18} />
        </div>

        {children && <div className={styles.content}>{children}</div>}

        {link && (
          <Link href={link.href}>
            <a className={classnames(styles.link, styles.linkNormal)}>
              {link.label}
              <Icon kind="arrow-right" size={12} />
            </a>
          </Link>
        )}
      </div>

      {link && (
        <div className={styles.linkContainerMobile}>
          <Link href={link.href}>
            <a className={styles.link}>
              {link.label}
              <Icon kind="arrow-right" size={12} />
            </a>
          </Link>
        </div>
      )}

      {links && links.length > 0 && (
        <div className={styles.rightContainer}>
          {links.map((link, i) => (
            <Link href={link.href} key={i}>
              <a className={styles.link}>
                {link.label}
                <Icon kind="arrow-right" size={12} />
              </a>
            </Link>
          ))}
        </div>
      )}

      {rightContent && <div className={styles.rightContainer}>{rightContent}</div>}
    </div>
  </div>
)

export default Banner
